/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import Footer from 'Component/Footer';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import CmsPage from 'Route/CmsPage';
import {
    HomePageContainer as SourceHomePageContainer,
    InstallPrompt,
    mapDispatchToProps,
} from 'SourceRoute/HomePage/HomePage.container';
import { isMobile } from 'Util/Mobile';

import { MAZOVIA_STORE } from './HomePage.config';

import './HomePage.style';

export { InstallPrompt };

/** @namespace Satisfly/Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    identifier: state.ConfigReducer.cms_home_page,
    storeCode: state.ConfigReducer.code,
});

/** @namespace Satisfly/Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    componentDidMount() {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: DEFAULT_STATE_NAME,
            isHiddenOnMobile: false,
        });

        this.insertWidget();
    }

    componentDidUpdate(prevProps) {
        const { storeCode } = this.props;
        const { storeCode: prevStoreCode } = prevProps;

        if (storeCode !== prevStoreCode) {
            this.insertWidget();
        }
    }

    insertWidget() {
        const { storeCode } = this.props;

        const widgetID = storeCode === MAZOVIA_STORE ? '41c81934-2620-4bf2-bba9-b1efa5ce37c2' : '852f7e1b-9535-45a6-8fff-19b94c0e5b94';
        const wrapper = document.getElementById(widgetID);
        const script = document.createElement('script');
        script.setAttribute('defer', 'defer');
        script.setAttribute('src', `https://trustmate.io/api/widget/${widgetID}/script`);

        if (wrapper) {
            wrapper.appendChild(script);
        }
    }

    renderTrustmate() {
        const { storeCode } = this.props;
        const widgetID = storeCode === MAZOVIA_STORE ? '41c81934-2620-4bf2-bba9-b1efa5ce37c2' : '852f7e1b-9535-45a6-8fff-19b94c0e5b94';

        return (
            <div id={ widgetID } />
        );
    }

    render() {
        const { storeCode } = this.props;

        return (
             <div block="HomePage">
                 <CmsPage { ...this.containerProps() } />
                 { storeCode && this.renderTrustmate() }
                 { isMobile.any() && (
                     <Footer isVisibleOnMobile />
                 ) }
             </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
